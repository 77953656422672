/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/components/layout.scss"

// Bownow Tracking
export const onClientEntry = () => {
  var bownowScript = document.createElement("script")
  bownowScript.id = "_bownow_ts"
  bownowScript.charset = "utf-8"
  bownowScript.src =
    "https://contents.bownow.jp/js/UTC_ca96483247c57e34c529/trace.js"

  document.getElementsByTagName("head")[0].appendChild(bownowScript)
}
